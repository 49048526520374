//Welcome popup
.registration,
.sign-in-page,
.welcome15-overlay {
  #loyalty_popup_offer_form {
    .welcome-overlay__checkbox {
      &__terms:checked + .label:before {
        background-color: inherit;
      }
      &.welcome-overlay__checkbox--second,
      &.welcome-overlay__checkbox--third {
        display: none;
      }
      .col-40 {
        @include breakpoint($small-only) {
          width: 100%;
        }
      }
    }
  }
  #cboxContent {
    .welcome-overlay {
      &__header {
        font-size: 36px;
      }
      &__copy {
        font-size: 17px;
      }
      &__checkbox {
        .label__content {
          font-size: 13px;
        }
      }
      &__label {
        font-size: 12px;
        margin-top: 30px;
        @include breakpoint($xsmall-down) {
          margin-top: 90px;
        }
      }
    }
  }
}

#loyalty_popup_offer_form {
  @include breakpoint($xsmall-down) {
    .welcome-overlay__submit__button {
      margin: 10px 0;
    }
  }
}

@include breakpoint($medium-up) {
  #main-focus-content {
    .marketing__benefits__section__header__tier {
      height: 100px;
    }
  }
}

@include breakpoint($medium-only) {
  .marketing__enrollment__signup-section {
    input.marketing__enrollment__email {
      width: 300px;
    }
  }
}

.marketing__enrollment {
  &__signup-section {
    input.marketing__enrollment__button {
      padding: 9px 20px;
      background: $color-light-gray;
      color: $color-black;
    }
  }
  &.offer_enrollment {
    .marketing__enrollment {
      &__accept {
        &__terms {
          &__checkbox:checked + .label {
            color: $color-grey-footer;
          }
          input[type='checkbox'] ~ .label::before {
            content: none;
          }
        }
      }
    }
  }
}

@media (min-width: 900px) {
  .pc-hide {
    display: none;
  }
}

//Registration Page
.new-account__item {
  &.checkbox__content {
    margin-bottom: 10px;
  }
  &_communication_consent {
    margin-left: 20px;
  }
  &__communication_sms_block {
    position: relative;
    overflow: hidden;
    min-height: 40px;
    .new-account__item__sms_promotions {
      float: left;
    }
    .new-account__item_phone1 {
      float: left;
      margin-left: 20px;
      input {
        margin: 0;
      }
    }
  }
  &.registration-notice {
    clear: both;
    margin-top: 0;
  }
}

.account__new-account .sign-in-page__indent {
  #registration_short .new-account__fieldset {
    input#form--registration_short--field--PASSWORD {
      margin-bottom: 0;
    }
  }
  .password.password-field {
    margin-bottom: 8px;
  }
}

#registration_short {
  .accept-privacy-info {
    display: none;
  }
  .label-content {
    width: calc(100% - 20px);
    max-width: 547px;
  }
}

.sign-in-page {
  p {
    margin-top: 0;
  }
}

@include breakpoint($tablet-down) {
  #signin {
    .main {
      .sign-in-page {
        .new-account {
          #registration_short {
            input {
              width: 90%;
            }
            input[type='submit'],
            input[type='button'] {
              width: 100%;
            }
            select {
              width: 90%;
            }
          }
        }
      }
    }
  }
}

//My account - landing page
.loyalty__panel__points__info-section {
  position: relative;
  .loyalty__panel__points__info-link {
    bottom: 17px;
    left: 10px;
  }
}

.tier-1,
.tier-2 {
  @include breakpoint($medium-up) {
    .loyalty__panel__points__info-section {
      .loyalty__panel__points__info-header {
        margin-bottom: 49px;
      }
    }
    .loyalty__panel__points__info-section.next-tier {
      .loyalty__panel__points__info-header {
        margin-bottom: 30px;
      }
    }
  }
}

.simple-loyalty-join-wrapper {
  &__cta-wrapper {
    @include breakpoint($small-only) {
      text-align: center;
      padding: 1em 0;
    }
  }
}

.local-grid {
  &__row {
    margin-left: -5px;
    margin-right: -5px;
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
  &__col {
    float: left;
    position: relative;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    @include breakpoint($landscape-up) {
      &-10 {
        width: 10%;
      }
      &-20 {
        width: 20%;
      }
      &-30 {
        width: 30%;
      }
      &-40 {
        width: 40%;
      }
      &-50 {
        width: 50%;
      }
      &-60 {
        width: 60%;
      }
      &-70 {
        width: 70%;
      }
      &-80 {
        width: 80%;
      }
      &-90 {
        width: 90%;
      }
    }
  }
}

.account {
  .selectbox {
    margin-top: 10px;
  }
  .form_element--extra__inputs_wrapper {
    @include breakpoint($small-only) {
      margin-bottom: 10px;
    }
  }
}

.touch,
.touchevents {
  .account {
    .selectbox {
      background-color: $color-white;
      color: $color-black;
      width: 100%;
      margin-bottom: 0;
    }
  }
}

.account-page__non__loyalty {
  #form--loyalty_join--field--BIRTH_DAY,
  #form--loyalty_join--field--BIRTH_MONTH {
    background-color: $color-white;
  }
}

//My account - loyalty page
.account__section--loyalty {
  .loyalty-page__content {
    .loyalty__panel__points__info-link {
      .info-link--hyper-link {
        display: block;
      }
    }
  }
  #loyalty__content {
    #loyalty__panel__transactions {
      .loyalty__panel__transactions__table {
        .loyalty__panel__transactions__transaction {
          .loyalty__panel__transactions__list__column-activity {
            white-space: normal;
            .loyalty__panel__transactions__list__column-activity__transactions {
              white-space: normal;
            }
          }
        }
      }
    }
  }
}

//Profile preferences
.newsletter-info__fieldset {
  .newsletter-info {
    &__item {
      @include breakpoint($small-only) {
        margin: 0;
      }
    }
    a {
      color: unset;
    }
  }
}

// Loyalty rewards landing for logged user in my account section
#loyalty__panel__earnpoints-landing {
  .earning-points {
    @include breakpoint($medium-up) {
      &__section {
        display: inline-flex;
        justify-content: space-between;
        flex-direction: column;
        height: 250px;
      }
      &__data__header,
      &__data__text {
        margin-bottom: 0;
      }
    }
  }
}

//Confirmation page
.confirmation-panel {
  .return-container {
    display: none;
  }
}

#loyalty-offer-code-panel {
  margin-top: 15px;
  border-bottom: 1px solid $color-black;
  .loyalty-offer-panel__title {
    border: none;
    padding: 0 25px;
    &.open {
      .title-icon {
        @include icon('arrow_down', 'after', false, false, 14px);
        transform: rotate(180deg);
      }
    }
  }
  .title-icon {
    @include title-icon;
    #{$rdirection}: 20px;
    @include breakpoint($medium-up) {
      #{$rdirection}: -5px;
    }
    &:after {
      font-weight: bold;
    }
  }
  .offer-code-wallet {
    &__code-info--applied {
      width: 200px;
      margin: auto;
    }
    &__code__description {
      width: 80%;
      margin: auto;
      text-align: center;
      &-title {
        font-weight: bold;
        margin: 5px 0;
      }
    }
    &__button--apply {
      margin: 5px 0;
    }
  }
  .loyalty-points-content {
    &__summary__non-member-content {
      font-weight: 700;
      text-align: center;
      display: inline-block;
      margin-bottom: 20px;
      width: 80%;
    }
    &__summary__member-content {
      text-align: center;
      margin-bottom: 5px;
    }
  }
  .loyalty-offer-points-panel__content {
    text-align: center;
  }
  .loyalty-info-icon {
    position: relative;
    font-size: 9px;
    border: 1px solid $color-gray-dark;
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    line-height: 1.4;
  }
  .loyalty-tooltip-content {
    display: none;
  }
  .offer-code-wallet__button--apply.button.btn-shopnow {
    color: $color-white;
    &:hover,
    &:focus,
    &:active {
      color: $color-white;
      background: $color-pink;
    }
  }
}

.account-page {
  & &__non__loyalty__container {
    height: auto;
    background-position: top left;
    padding-bottom: 60px;
    @if ($lr2021) {
      background-size: contain;
      background-image: url('/media/export/cms/loyalty/account_loyalty_banner_pc_bg_lr2021_vertical.jpg');
      background-color: $color-black;
      padding-top: 90%;
    } @else {
      background-size: cover;
      background-color: $color-gray;
    }
  }
  & &__non__loyalty__loyalty__text {
    width: 100%;
  }
}

.non__loyalty__panel__join.local-grid {
  color: $color-white;
  %common {
    margin-top: 10px;
    a {
      color: $color-white;
      text-decoration: underline;
    }
  }
  @include breakpoint($medium-up) {
    width: 95%;
  }
  input[type='text'] {
    width: 100%;
    @extend %common;
  }
  .label {
    @extend %common;
    display: block;
  }
  .accept-privacy-info {
    @extend %common;
  }
}

.account-mobile__non__loyalty.loyalty-membership-section {
  .info-link {
    display: none;
  }
  .fs.loyalty_join,
  .info-link--fr,
  .account-mobile__non__loyalty__disclaimer--secondary {
    display: block;
  }
  .info-link--fr {
    text-decoration: underline;
  }
}

.account .account__section .account-mobile__non__loyalty__join__link.button--disabled {
  background: $color-gray;
}

.form_element--extra {
  input[type='text'] {
    width: 100%;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .loyalty__panel__offers__sku__product__image-container {
    height: auto;
  }
  #loyalty__panel__earnpoints-landing {
    .earning-points__section {
      position: relative;
      display: inline-block;
      height: auto;
      padding-bottom: 30%;
    }
    .earning-points__data__header {
      top: 30px;
      left: 50%;
      position: absolute;
      transform: translate3d(-50%, 0, 0);
    }
    .earning-points__data__text {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate3d(-50%, -50%, 0);
      font-size: 20px;
      white-space: nowrap;
    }
    .earning-points__data__footer {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }
  }
  .loyalty__panel__transactions__list__column-activity__transactions,
  .loyalty__panel__transactions__list__column-activity {
    overflow: initial;
    text-overflow: initial;
    white-space: initial;
  }
}

@media only screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
  #loyalty__panel__earnpoints-landing {
    .earning-points__section {
      padding-bottom: 55%;
    }
  }
  .loyalty__panel__transactions__list__column-earned__points-earned.mobile-hidden {
    display: block !important; //LOYAL-9171
  }
}

.tabbed-rewards-block__tabs {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1100px) {
  .site-header .site-utils__menu--v2 {
    padding: 0 10px;
  }
}

@include breakpoint($medium-up) {
  .welcome15-overlay {
    .welcome-overlay {
      height: inherit;
      &__rightcontent {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

//points history
#loyalty__panel__points-instruction {
  .loyalty__panel__points-less-content {
    display: none;
  }
  .loyalty__panel__points-instruction-show-more,
  .loyalty__panel__points-instruction-show-less {
    cursor: pointer;
  }
}

// SKUs adjustment
.loyalty__panel__offers__sku__product__sub-header {
  display: none;
}

// My benefits carousel
.loyalty__panel__offers__offer {
  min-height: 450px;
}

.loyalty__panel__offers__offer-wrapper {
  min-height: inherit;
  display: flex;
  flex-flow: column wrap;
}

.loyalty__panel__offers__cta {
  margin-top: auto;
}
