.account-page {
  .account-page__content {
    .account-profile {
      h3.section-header__header {
        border-bottom: 2px solid;
        padding-bottom: 5px;
      }
    }
    .account-payment,
    .account-address {
      h3.section-header__header {
        padding-bottom: 20px;
      }
    }
  }
}

.profile-page__content #profile_preferences {
  .profile-info__item.title_container {
    width: 100%;
  }
}

.address-overlay-shipping,
.address-overlay-billing,
#shipping-address-info-panel #address_form_container {
  fieldset {
    .address-form__header {
      margin-left: 0px;
    }
    .address-form__content {
      .form_element {
        margin-bottom: 6px;
        input,
        select {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .title_container {
        margin-top: 6px;
      }
      .postal_code_container {
        input {
          width: 50%;
          margin-right: 10px;
        }
        .postal-code-error {
          color: #ff4661;
        }
      }
      .city_container .city-results {
        margin-top: 10px;
        margin-bottom: 15px;
        a {
          border: 1px solid;
        }
      }
    }
  }
}

.newsletter-info {
  .sms_promotions {
    margin: 0 0 20px 20px;
  }
}

.profile-pic-upload__form-wrapper {
  .upload-file__button button {
    font-size: 12px;
  }
  .upload-file__value {
    font-size: 12px;
    left: inherit;
    right: 50px;
  }
}

.password-sent-page .password-sent-page__content {
  .password-sent__text .sent-info__text {
    margin: 0 0 10px 20px;
  }
}

.password-reset-page .password-reset-page__content {
  .password-reset .password-reset__form {
    .password-reset__fieldset .password-reset__item input {
      display: block;
      margin-top: 10px;
    }
  }
}

.account__new-account .sign-in-page__indent {
  #registration_short .new-account__fieldset {
    input#form--registration_short--field--PASSWORD {
      margin-bottom: 20px;
    }
  }
}

.order-details-page .order-details-page__content {
  .order-products {
    .cart-item.sample {
      .cart-item__qty-label {
        display: block;
      }
    }
    .cart-item:last-child {
      border-bottom: 1px solid #bbbbb9;
    }
    .cart-header {
      font-weight: bold;
    }
    .cart-item {
      .cart-item__desc {
        width: 34%;
      }
      @media only screen and (max-device-width: 768px) {
        .cart-item__desc {
          width: 28%;
        }
        .cart-item__qty {
          width: 14%;
        }
      }
      .cart-item__price {
        width: 21%;
      }
      .cart-item__total {
        text-align: right;
        float: right;
      }
    }
  }
}

#colorbox {
  .create-wishlist__form #alter_list {
    .create-wishlist__header {
      display: block;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .account-page .account-page__content {
    section {
      word-wrap: break-word;
      .section-header,
      .section-head {
        padding: 0px;
        display: block;
        margin-bottom: 20px;
        h3 {
          margin-bottom: 20px;
          border-bottom: 2px solid;
          padding-bottom: 5px;
        }
      }
      .section-header:before,
      .section-head:before {
        background: none;
      }
    }
  }
}

.favorites-page {
  .favorites-page__content {
    .favorites-mylists .my-lists__list-actions {
      .my-lists__list-link--share:before {
        top: 24px;
      }
    }
    .favorites__instruction-step {
      .favorites__instruction-step-title {
        line-height: 30px;
      }
      .favorites__recommended-products {
        .favorites__recommended-product {
          .description {
            .product_name {
              min-height: 76px;
              a {
                border: none;
              }
            }
            @media only screen and (min-device-width: 1024px) {
              .formatted_price {
                min-height: 75px;
              }
            }
          }
          @media only screen and (max-device-width: 768px) {
            .description .product_name {
              min-height: 105px;
            }
            .formatted_price {
              min-height: 100px;
              text-align: left;
              .swatch-container {
                margin-top: 10px;
                width: 100%;
              }
            }
            a.favorites__recommended-product-add-to-favorites {
              margin-right: 0px;
            }
          }
          .favorites__recommended-product-actions {
            position: relative;
            right: 0px;
            a.favorites__recommended-product-add-to-favorites {
              float: left;
            }
            a.favorites__recommended-product-add-to-cart {
              width: 82%;
              padding: 15px 0px 15px 0px;
            }
          }
        }
      }
    }
  }
}

.order-confirmation {
  .order-details-page .order-details-page__content {
    .order-products .cart-item {
      .cart-item__desc {
        width: 25%;
      }
      .cart-item__price {
        width: 19%;
      }
      .cart-item__qty {
        width: 24%;
      }
      @media only screen and (max-device-width: 768px) {
        .cart-item__qty {
          width: 22%;
        }
      }
    }
  }
}

.favorites-page__content {
  .favorites {
    &__recommended-product {
      min-height: 440px;
      position: relative;
      &-actions {
        position: absolute;
        bottom: 0;
        right: 15px;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .account-page {
    .account-page__content {
      .account-address {
        .section-content {
          padding-left: 3px;
        }
      }
    }
  }
  .favorites-page__content {
    .favorites {
      &__recommended-product {
        min-height: 400px;
      }
    }
  }
}

.past-purchases-page__content {
  width: auto;
  overflow: hidden;
  padding-bottom: 1px;
  .product-item {
    border-top: 1px #bbbbb9 solid;
    padding-top: 15px;
    margin-bottom: 15px;
  }
  .product-item:first-child {
    border-top: none !important;
  }
  .product-item__name a {
    font-family: 'Brandon Text Bold';
    font-weight: normal;
    font-size: 11px;
    line-height: 19px;
    letter-spacing: 0.22em;
    text-transform: uppercase;
    color: black;
    text-decoration: none;
  }
  .product-item__swatch {
    display: inline-block;
  }
  .product-item__shade {
    display: inline-block;
    vertical-align: top;
    line-height: 21px;
  }
  .product-item__add-to-cart {
    margin: 20px 0px;
  }
}

.account-utilities {
  .account-utilities__section .sidebar-menu {
    li.sidebar-menu__item--address,
    li.sidebar-menu__item--favorites {
      letter-spacing: 0.17em;
    }
  }
}

#profile_pic_content {
  display: none;
}
