.customer-service {
  .customer-service-quick-info {
    .customer-service-quick-info__list-item {
      margin-left: 0;
    }
  }
}

.customer-service-section {
  #landing-chat {
    border-bottom: 1px solid #d1d2d1;
    #service_live_chat {
      display: block;
      a {
        bottom: 0px;
        position: relative;
      }
    }
  }
  #landing-email {
    border-top: none;
    border-bottom: none;
    border-right: 1px solid #d1d2d1;
  }
  #landing-faqs {
    border-right: none;
  }
}

.customer-service-quick-info__list {
  #cs_chat {
    a.customer-service-quick-info__link {
      margin-top: 10px;
      margin-left: 65px;
    }
    div.LPMcontainer {
      position: inherit !important;
    }
  }
}

#lpChat {
  .lp_radio_button {
    label:before,
    label:after {
      display: none;
    }
  }
}

.touch .device-mobile {
  #lpChat .lp_lpview_content select {
    -moz-appearance: menulist;
    -webkit-appearance: menulist;
    appearance: menulist;
  }
}

body.page-product-118723 {
  .elc-ugc-v1 {
    display: none;
  }
}
