@media (min-width: 768px) {
  .site-nav {
    .menu__item--lvl-1 {
      vertical-align: top;
    }
  }
}

.hot-collection {
  .product_collection_hero__content-item {
    .button--disabled {
      background-color: #ccc;
      border: 1px solid #ccc;
    }
    .js-quickshop-container {
      .quickshop {
        .product {
          .product__details {
            .product__actions {
              padding: 5px 0 0 10px;
              .product__button {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
    .block-template-product-collection-prodcut-p-v1 {
      .content {
        .product-palette-atb {
          .product__button--add-to-bag {
            width: 80%;
          }
        }
      }
    }
  }
}

.greige-collection {
  max-width: 1024px;
  margin: 0 auto;
  .product-collection-tabs .tab-item a.current {
    border-bottom: none;
  }
  .product-collection-tabs .tab-item a {
    background-color: #ffffff;
  }
  .callout-alt {
    .button {
      background-color: #ff4661;
      color: #ffffff;
      width: 205px;
    }
    .button--disabled {
      background-color: #cccccc;
      border: 1px solid #cccccc;
    }
  }
  .product__inventory-status {
    font-family: Brandon Text;
    text-align: left;
    padding: 3px 0;
  }
  .quickshop .product-gallery {
    border-right: none;
  }
  .quickshop__container {
    background-color: #ffffff;
    margin: 0;
  }
  .quickshop-wrapper {
    position: fixed;
    z-index: 100;
    width: 768px;
    max-height: 77%;
    overflow: auto !important;
    top: 12.5%;
    left: 0;
    right: 0;
    margin: 5% auto;
    &.active::before {
      display: none;
    }
    .wrap {
      padding: 0;
    }
    .shade-picker__shade {
      width: 39px;
      height: 39px;
    }
    .button {
      float: left;
    }
  }
  .product__promo,
  .product__full-details-link-wrapper {
    display: none;
  }
  .product--full .product__sku-categories {
    min-height: 0;
  }
  .product-collection-product div,
  .product-collection-product__wrap div {
    background-repeat: no-repeat;
  }
  .product_collection_hero {
    height: 361px;
    &__body {
      color: #ffffff;
      width: 380px;
      height: 160px;
      position: relative;
      top: 126px;
      left: 103px;
    }
    h1 {
      font-family: Brandon Text Bold;
      font-size: 30px;
      line-height: 33px;
      letter-spacing: 9px;
      text-transform: uppercase;
      text-align: center;
      margin: 0 auto 18px auto;
    }
    &__subtitle {
      font-size: 20px;
      line-height: 33px;
      letter-spacing: 5px;
      text-align: center;
      margin: 0 auto 0 auto;
    }
    &__content {
      overflow: hidden;
    }
    &__content-item {
      float: left;
      width: 100%;
      position: relative;
    }
    &__content-item:nth-child(3) {
      width: 50%;
      min-height: 619px;
    }
    &__content-item:nth-child(4) {
      width: 50%;
      float: right;
      min-height: 619px;
    }
    &__content-item:nth-child(6) {
      width: 50%;
      min-height: 509px;
    }
    &__content-item:nth-child(7) {
      width: 50%;
      float: right;
      min-height: 509px;
    }
  }
  .product-collection-product {
    &__description {
      font-size: 16px;
      margin-bottom: 3px;
    }
    &__callout {
      font-family: Brandon Text Bold;
      font-size: 16px;
      color: #ff4661;
      text-transform: uppercase;
      letter-spacing: 3px;
      margin-bottom: 6px;
    }
    h2,
    h3 {
      font-family: Brandon Text Bold;
      font-size: 24px;
      margin-bottom: 6px;
      text-transform: uppercase;
      letter-spacing: 3px;
      line-height: 1em;
    }
    h3 {
      margin-top: 6px;
    }
    &__price {
      font-family: Brandon Text Bold;
      font-size: 16px;
    }
  }
  .button--outline {
    border: 1px solid #ff4661;
    color: #ff4661;
    margin-top: 18px;
  }
  .product__button {
    margin-top: 18px;
  }
  .product_eye-palette {
    width: 1024px;
    height: 371px;
    padding: 90px 30px 0 53%;
    position: relative;
    border-bottom: 1px solid #f3f3f3;
    background-position: 30px 30px;
    .product-collection-product {
      &__callout {
      }
    }
  }
  .product_intense-pigment {
    font-size: 16px;
    height: 350px;
    padding: 40px 50% 0 30px;
    border-bottom: 1px solid #f3f3f3;
    background-position: 90% center;
  }
  .product-palette-atb {
    font-size: 16px;
    font-family: Brandon Text Bold;
    position: absolute;
    top: 215px;
    h3 {
      text-transform: uppercase;
    }
    p {
      margin-bottom: 6px;
    }
    &.product_1 {
      left: 30px;
    }
    &.product_2 {
      left: 220px;
    }
    &.product_3 {
      left: 410px;
    }
  }
  .product_nail-polish {
    height: 560px;
    background-position: center 30px;
    border-right: 1px solid #f3f3f3;
    padding: 363px 30px 0;
  }
  .product_glitter-nail-polish {
    border-right: 1px solid #f3f3f3;
    padding: 0 30px 30px;
    & h2 {
      display: none;
    }
  }
  .product_sheer-lip {
    height: 619px;
    background-position: center 53px;
    padding: 363px 30px 0;
  }
  .product_shadow-stick {
    height: 380px;
    background-position: center 30px;
    padding: 200px 30px 0;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
  }
  .product_smokey-mascara {
    height: 569px;
    border-right: 1px solid #f3f3f3;
    background-position: center 10px;
    padding: 280px 30px 0 50%;
    & .product-collection-product__price {
      width: 134px;
      margin-top: 15px;
    }
  }
  .product_brightening-brick {
    height: 539px;
    background-position: center 15px;
    padding: 380px 30px 0;
  }
  .product_brush {
    height: 300px;
    background-position: center 30px;
    padding: 100px 30px 0;
    border-top: 1px solid #f3f3f3;
  }
  .product-collection-footer {
    height: 240px;
    h3 {
      font-family: Brandon Text Bold;
      font-size: 22px;
      text-transform: uppercase;
      margin: 18px 0;
    }
    &__sub {
      font-size: 20px;
      line-height: 1.5em;
      margin-bottom: 18px;
    }
    &__link a {
      font-family: Brandon Text Bold;
      text-transform: uppercase;
      text-decoration: none;
      border-bottom: 1px solid;
      padding-bottom: 3px;
    }
  }
  .shop-all__button {
    margin: 27px 0;
  }
  .product-collection-footer_l,
  .product-collection-footer_r {
    height: 240px;
    width: 510px;
    margin: 40px 0;
    float: left;
    background-color: $color-gray-light-alt;
  }
  .product-collection-footer_l {
    padding-top: 30px;
    span {
      font-size: 3em;
      text-align: center;
    }
  }
  .product-collection-footer_r {
    margin-left: 4px;
    padding-top: 37px;
  }
  .block-template-product-collection-prodcut-p-v1 {
    .content {
      .product-palette-atb {
        .product__button--add-to-bag {
          width: 80%;
        }
      }
    }
  }
}

.iss_c_c {
  .product_collection_hero {
    min-height: 700px;
  }
  .product_collection_hero__content-item:nth-child(1) {
    top: 275px;
  }
  .product_collection_hero__content-item:nth-child(2) {
    top: 535px;
  }
}

.section-issf-spf-40 {
  .detail_hero_issf-spf-40 {
    .detail_hero {
      min-height: 696px;
    }
  }
}

.spp {
  .responsive-tabs--how_to_use-wrap {
    .how-to-use__content {
      .disclaimer {
        padding-top: 0.75em;
      }
    }
  }
}
