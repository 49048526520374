.section-store-locator.device-pc {
  #store-search-controls {
    input.search {
      width: 230px;
    }
  }
  .store-locator__main {
    .gmnoprint {
      div[title^='Pan left'],
      div[title^='Pan down'],
      div[title^='Pan right'],
      div[title^='Pan up'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
}

.section-customer-service-contact-us {
  .customer-service-section {
    .customer-service-contact-form__category {
      letter-spacing: 2px;
    }
    .customer-service-contact-form__section {
      #category-online-content {
        .customer-service-contact-form__radio-item label::before {
          font-size: 13px;
          margin-top: 1px;
        }
        .customer-service-contact-form__radio-item label {
          .orderstatus_exchanges_radiobutton {
            display: inline-block;
            margin-top: 1px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.telluride-collection {
  .lw-gel,
  .text-block {
    min-height: 410px;
  }
  .smokey-eye {
    .product-collection-product {
      bottom: 0;
    }
  }
  .pot-rouge {
    .product-collection-product {
      bottom: 23px;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .section-customer-service-contact-us {
    .customer-service-section {
      .customer-service-contact-form__category {
        letter-spacing: 0px;
        padding: 1em 5px;
      }
    }
  }
  .customer-service-menu {
    a.menu__link--lvl-1 {
      width: auto;
      height: auto;
    }
  }
  .device-pc {
    .bobbis-lessons {
      &__footer {
        margin: 0 2% 0 2%;
      }
    }
    .makeup-lessons-item {
      &__wrap {
        padding: 30px 0 25px 0;
      }
    }
    .makeup-lessons-items {
      &__lessons {
        bottom: 35px;
        width: auto;
        .makeup-lessons-item {
          margin: 9px 9px 9px 0px;
        }
      }
    }
    .telluride-collection {
      .lw-gel,
      .text-block {
        min-height: 475px;
      }
      .shimmer-brick,
      .sheer-lip-color {
        min-height: 620px;
      }
      .pot-rouge {
        .product-collection-product {
          bottom: 42px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .section-store-locator {
    .store-locator__form-container {
      #store-search-controls {
        display: block;
      }
    }
    .store-locator__main .responsive-tabs {
      .resp-tabs-list {
        li:first-child {
          width: 60%;
        }
        li:last-child {
          width: 40%;
        }
      }
    }
  }
}

.checkout {
  .checkout__sidebar {
    section {
      margin-bottom: 10px;
      background: #eff0f0;
      padding: 16px 20px;
      p {
        margin-bottom: 15px;
      }
    }
    .account-utilities__section {
      h3.account-utilities__header {
        font-family: 'Brandon Text Bold';
        margin: 4px 0 10px 0;
        padding-bottom: 17px;
        border-bottom: 1px solid #000000;
        text-transform: uppercase;
      }
    }
    #links-panel {
      ul.links_list {
        li {
          border: 0px;
          margin-bottom: 10px;
        }
      }
    }
    #offer-code-panel {
      .offer-code-panel__content {
        margin-top: 0px;
        #offer_code .offer_code_form_container {
          #one-offer-only {
            display: block;
            margin: 10px 0px;
          }
        }
      }
    }
    .return-link-container {
      margin-bottom: 10px;
    }
    .payments-icon {
      .logo {
        &_visa {
          background: url('/media/images/checkout/payment_logo/BB-VISA.png') no-repeat;
        }
        &_mc {
          background: url('/media/images/checkout/payment_logo/BB-MC.png') no-repeat;
        }
        &_paypal {
          background: url('/media/images/checkout/payment_logo/BB-Paypal.png') no-repeat;
        }
        &_amex {
          background: url('/media/images/checkout/payment_logo/americanexpress.gif') no-repeat;
        }
      }
    }
  }
  .checkout__content {
    #viewcart-panel {
      .viewcart-panel__content {
        margin-top: 10px;
        .total_column.sub__total {
          text-align: right;
          margin: 10px 0px;
        }
      }
      h2.viewcart-panel__title {
        border-bottom: 1px solid;
      }
      div.messages {
        margin: 10px 0px;
        ul li {
          margin-left: 0px;
        }
      }
    }
    #shipping-panel {
      .transaction-panel-details {
        margin-top: 20px;
        .transaction-item {
          float: left;
          margin: 0 28px 28px 0;
          position: relative;
          width: 275px;
          word-wrap: break-word;
        }
      }
    }
    #review-instructions {
      padding: 15px 0px;
      p {
        margin: 0px;
      }
    }
  }
  .below-columns {
    #recommended-products-panel {
      .recommended-products-panel__content .recommended-product-items .recommended-item {
        .description {
          margin: 10px 0px;
          .product_name {
            min-height: inherit;
          }
          div {
            margin: 0px 0px 10px 0px;
          }
          .color .swatch {
            margin-right: 10px;
          }
        }
      }
    }
  }
  ol.checkout-progress li {
    width: 195px;
    float: left;
    margin: 24px 24px 0;
    list-style-type: decimal;
  }
  .checkout-buttons-container {
    text-align: right;
    margin-bottom: 30px;
  }
  #checkout_review .newsletter-info {
    p {
      margin: 0px 0px 15px 0px;
    }
    .newsletter-info__item {
      margin-left: 0px;
      padding-left: 0px;
    }
  }
}

#samples-panel #checkout_samples {
  .samples-buttons.bottom {
    display: block;
  }
  .samples {
    .deactivate {
      .sample-select-button,
      .sample-select-button:hover {
        opacity: 0.3;
        cursor: default;
        background-color: #000000;
      }
    }
  }
}

.shipping {
  .checkout-progress li.checkout-progress__shipping {
    font-weight: bold;
  }
  .checkout__content {
    #shipping-page-info-panel #checkout_shipping {
      section {
        margin-bottom: 10px;
      }
      #shipping-address-info-panel {
        #address_form_container .address {
          .address-form__content {
            .title_container {
              margin-bottom: 10px;
            }
          }
        }
        #shipping-info-panel {
          padding: 0px;
          .shipping-address {
            margin-bottom: 10px;
          }
          ul.address_controls li {
            margin-bottom: 10px;
            &#choose-address select {
              margin-top: 5px;
            }
          }
        }
        h2.shipping-info-panel__title {
          padding-top: 15px;
        }
      }
      #billing-address-info-panel #billing-info-panel {
        padding: 0px;
        .billing-address {
          margin-bottom: 10px;
        }
      }
      #delivery-info-panel #delivery-info-content-panel {
        padding: 0px;
        #delivery-options {
          p {
            margin-bottom: 10px;
          }
        }
      }
      #emailpromo-info-panel .newsletter-info {
        padding: 0px;
        h3.newsletter-info__header {
          text-indent: 0px;
        }
        p {
          margin-left: 0px;
          margin-bottom: 15px;
        }
        .newsletter-info__item {
          margin-left: 0px;
          padding-left: 0px;
        }
        .sms_promotions {
          margin: 0px;
        }
      }
    }
  }
}

.review {
  .checkout-progress li.checkout-progress__review {
    font-weight: bold;
  }
  .checkout__content {
    #checkout_review {
      .promotions-container {
        h3.newsletter-info__header {
          text-indent: 0px;
        }
      }
      .sms_promotions {
        margin-left: 0px;
      }
    }
  }
}

.billing {
  .checkout-progress li.checkout-progress__billing {
    font-weight: bold;
  }
  #checkout_complete {
    #payment-info-panel {
      .payment_method_container {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        p,
        .checkout__panel-title {
          display: none;
        }
        div {
          &.direct-debit .direct-debit-nickname input {
            margin-bottom: 0px;
          }
        }
        #payment-type-ncc {
          order: 2;
        }
        #payment-type-cc {
          order: 1;
        }
        .legal_txt {
          order: 3;
          label {
            display: flex;
          }
        }
        .payment-type {
          margin-block-end: 10px;
          float: none;
          padding: 14px 10px;
          border: 1px solid $color-core-black;
          label {
            display: flex;
            align-items: center;
            gap: 10px;
            overflow: hidden;
          }
        }
      }
    }
    input[type='radio'] {
      & ~ label::before {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        position: relative;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid $color-nobel;
      }
    }
    input[type='radio']:checked {
      & ~ label::before {
        border: 1px solid $color-core-black;
        padding: 2px;
        background-color: $color-core-black;
        box-shadow: inset 0 0 0 4px $color-white;
      }
    }
  }
  #promo-message {
    margin-bottom: 15px;
  }
}

.confirm {
  .checkout-progress li.checkout-progress__confirm {
    font-weight: bold;
  }
  .checkout__content {
    #confirm-panel {
      div {
        margin-bottom: 10px;
      }
    }
  }
}

.order-confirmation {
  .account-utilities {
    display: none;
  }
  .cart-item {
    margin-top: 20px;
  }
  .cart-item__thumb {
    width: 13%;
    margin-right: 10px;
  }
  .cart-item__desc {
    float: left;
    width: 30%;
    margin-left: 10px;
  }
  .cart-item__price {
    text-align: center;
  }
  .cart-item__qty {
    float: left;
    clear: none;
    padding-top: 0;
    margin: 0 10px;
    width: 14%;
    text-align: center;
  }
  .cart-item__total {
    width: 14%;
    float: left;
    line-height: 36px;
    text-align: right;
  }
  .order-totals {
    float: right;
    table {
      th,
      td {
        border: 0px;
      }
    }
  }
}

.site-footer .site-footer-contact {
  padding-left: 15px;
  ul {
    li.site-footer-contact__item--phone {
      width: auto;
    }
    li.site-footer-contact__item--email {
      padding-left: 0px;
      width: 36.3%;
    }
  }
}

.order-details-page__content .order-totals {
  width: 40%;
  float: right;
  table {
    th {
      width: 80%;
    }
    td {
      width: 20%;
    }
  }
}

.section-store-locator {
  .store-locator__links-container {
    .store-locator__dutyfree {
      display: none;
    }
    .store-locator__link-events {
      float: right;
      margin: 5px 0 0 20px;
    }
  }
}

.favorites-page__content {
  .favorites .my-lists__list-actions {
    .my-lists__list-link--share:before {
      top: 24px;
    }
  }
}

#samples .samples-page .product {
  min-height: 482px;
  width: 21.6%;
}

#recommended-products-panel {
  .recommended-product-items .recommended-item {
    padding-bottom: 60px;
    .add_button {
      position: absolute;
      bottom: 0;
    }
    .column.item_info .product_subname {
      height: 20px;
    }
  }
}

.elc-user-state-anonymous {
  .site-email-sms-signup {
    padding-top: 15px;
    .site-email-sms-signup__agree {
      input#form--email_signup--field--SMSPROMO_SMS_PROMOTIONS {
        display: none;
      }
    }
    .site-email-sms-signup__header {
      h2 {
        margin-bottom: 10px;
      }
    }
  }
}

.site-header {
  .site-utils__dropdown--search {
    .search-form__fields {
      .search-form__submit {
        letter-spacing: 0.3px;
      }
    }
  }
  &__menu-list {
    @include breakpoint($medium-portrait-only) {
      margin-#{$ldirection}: 100px;
    }
  }
  .site-nav--sticky {
    .site-header__menu-list {
      @include breakpoint($medium-portrait-only) {
        margin-#{$ldirection}: 0;
      }
    }
    .site-utils__search {
      @include breakpoint($medium-portrait-only) {
        #{$ldirection}: 8px;
      }
    }
  }
}

input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.section-esearch {
  #perlgem-search-form {
    .search-form__submit {
      letter-spacing: 0.03em;
      margin-#{$ldirection}: 0;
    }
    .search-form__results-count {
      line-height: 2.5;
      #{$rdirection}: 181px;
    }
  }
  #search-wrapper {
    #results-container {
      #col-1 {
        /* stylelint-disable-next-line declaration-no-important */
        display: none !important;
      }
      #col-2 {
        float: none;
        width: 100%;
        #product-results {
          margin-#{$ldirection}: 0;
          .results-header {
            margin-#{$ldirection}: 20px;
          }
          .product-grid__content {
            max-width: 1440px;
            display: flex;
            flex-wrap: wrap;
            .product-grid__item {
              width: calc(100% / 3);
            }
          }
        }
      }
    }
  }
}

.site-utils__dropdown--account {
  .sign-in {
    .registration form input {
      width: 100%;
    }
    .registration__password-container {
      overflow: hidden;
    }
    .registration__show-pass {
      margin: 5px 0 0 0;
    }
    .registration__show-pass-text {
      letter-spacing: 0em;
    }
    .registration__terms {
      margin-top: 10px;
    }
    .registration__email-list {
      margin: 10px 0 18px 0;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .device-pc {
    .site-footer {
      .site-footer-contact {
        .site-footer-contact__link {
          letter-spacing: 0.39px;
          margin-left: 0;
        }
      }
    }
    .site-email-signup {
      &__field {
        width: 60%;
        padding: 5px 5px;
      }
      &__submit {
        height: 32px;
        letter-spacing: 0px;
        width: 37%;
        padding: 0px;
        font-size: 13px;
      }
    }
  }
}

@media only screen and (max-device-width: 1024px) and (orientation: landscape) {
  .site-footer {
    .site-footer-contact {
      .site-footer-contact__item--phone {
        padding-right: 60px;
      }
      .customerservice_width {
        .site-footer-contact__link {
          margin-left: 10%;
        }
      }
    }
    .site-email-signup {
      .site-email-signup__submit {
        height: 32px;
      }
    }
  }
}

@media only screen and (max-device-width: 768px) {
  .site-footer .site-footer-contact ul {
    li.site-footer-contact__item--phone {
      float: none;
      width: 100%;
      text-align: center;
    }
    li.site-footer-contact__item--email {
      width: 100%;
      text-align: center;
    }
  }
  .quickshop {
    .product__details .product__actions {
      .product__full-details-link-wrapper {
        float: none;
        margin-bottom: 10px;
      }
      .product__notify-me {
        margin-top: 10px;
      }
    }
  }
}

.site-header__addtobag {
  .site-header__addtobag-cell {
    .site-header__addtobag__product-info {
      div {
        font-size: 13px;
      }
      @media only screen and (max-device-width: 1024px) {
        div {
          font-size: 12px;
        }
      }
    }
    .site-header__addtobag-button {
      a {
        font-size: 13px;
      }
      @media only screen and (max-device-width: 1024px) {
        a {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .section-customer-service {
    .customer-service-section {
      .customer-service-landing-block {
        height: 310px;
        .customer-service-landing-block__header {
          padding: 1.5em 0px;
        }
        .customer-service-landing-block__link {
          bottom: 15px;
        }
      }
    }
  }
}

@media only screen and (max-device-width: 768px) {
  .spp {
    .responsive-tabs--how_to_use-wrap .resp-tab-item {
      padding: 8px 9px;
      font-size: 12px;
    }
  }
}

.ie8 {
  .section-store-locator {
    .store-locator--desktop {
      .store-locator__locate.geo_search,
      .store-locator-either-or {
        display: none !important;
      }
    }
  }
  .sign-in {
    .account-links .account-links__container {
      li {
        height: auto !important;
      }
    }
  }
  .makeup-lessons-item__thumb-image {
    max-width: none;
  }
}

#samples-panel {
  .samples-buttons.bottom {
    display: block;
  }
}

.site-utils {
  &__item--cart {
    .cart_overlay {
      .full_view {
        .subtotal {
          .sub_t {
            width: 115px;
            .sub_num {
              float: left;
              margin-left: 40px;
            }
          }
        }
      }
    }
  }
}

.ie8 {
  .sign-in .registration__terms-text {
    padding-left: 0px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .offers-hero {
    .offers-hero__button {
      margin: 15px 0 0 15px;
    }
  }
  .offers-details {
    .offers-details-item__button {
      margin: 13px 0 0 25px;
    }
  }
}

.offers-hero__container {
  .offers-hero__description {
    .offers-hero__text {
      min-height: 201px;
    }
  }
}

.site-utils__nav {
  .menu {
    .only-static-text {
      display: block;
    }
  }
}

#colorbox {
  .create-wishlist__form {
    .create-wishlist__name {
      width: 61%;
    }
  }
}

.bobbis-lessons__footer {
  a.link--get-the-look {
    padding-right: 60px;
    word-wrap: break-word;
    white-space: pre-wrap;
    width: 50%;
  }
  .bobbis-lessons__footer--right a.link {
    padding: 0px;
  }
}

.gtl__steps__content {
  .gtl__steps__body p {
    width: 95%;
  }
}

.makeup-lessons__header {
  &--content h2 {
    letter-spacing: 3px;
    text-transform: uppercase;
  }
}

@media only screen and (max-device-width: 768px) {
  .makeup-lessons__header--content h2 {
    font-size: 35px;
  }
  a.makeup-lessons__header {
    background-size: 650px 424px;
  }
  div.makeup-lessons__header {
    background-size: 480px 328px;
  }
}

.mpp.gift_guide {
  .product-filter {
    width: 900px !important;
  }
}

.ie8 {
  .page-header--fit-to-img {
    .page-header__text-wrapper--center-vertically {
      top: 25%;
    }
  }
  .makeup-lessons__header {
    .makeup-lessons__header--content {
      .inner.slvzr-first-child {
        top: 25%;
      }
    }
  }
}

.bobbi-glow {
  .product_highlighting-powder {
    min-height: 345px;
    background-position: center 20px;
    padding: 350px 30px 30px 0;
    border-top: 1px solid #eff0f0;
    border-bottom: 1px solid #eff0f0;
  }
}

.site-footer {
  .trimanalign {
    span {
      font-size: 11px;
      letter-spacing: 0px;
      img {
        margin-right: 3px;
        float: left;
      }
    }
    a {
      float: left;
      font-size: 11px;
      letter-spacing: 0px;
      line-height: 8px;
    }
  }
}

.offer-code-panel {
  .offer_code_form_container {
    em#one-offer-only {
      span {
        a {
          margin-top: 10px;
        }
      }
    }
  }
}

/**  power-review  **/
#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-helpful {
        &-yes {
          .pr-helpful-count {
            &:before {
              content: 'utile';
              visibility: visible;
            }
          }
        }
        &-no {
          .pr-helpful-count {
            &:before {
              content: 'peu utile';
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

#pr-war-form {
  .tag-group {
    .input-add-tag {
      width: 89%;
    }
  }
  .pr-vosproblèmesdepeau-form-group,
  .pr-lesbénéficesdesproduits-form-group {
    .pr-control-label {
      float: left !important;
      margin-right: 20px;
    }
  }
}

.spp {
  .responsive-tabs--how_to_use-wrap {
    .resp-tab-item {
      padding: 8px 10px !important;
      letter-spacing: 3px !important;
    }
  }
}

.js-sticky-header {
  .js-site-header-sticky {
    .site-utils__inner {
      max-width: 100%;
      height: auto;
    }
  }
}

#bt_notification {
  .bt-content {
    .btn-wrap {
      a.btn {
        font-family: Brandon Text Bold;
      }
    }
  }
}

.pg-checkout {
  .site-header {
    nav {
      &.site-nav--primary {
        display: block;
        .gnav-section {
          margin: 0 10px;
        }
      }
    }
  }
  .site-footer {
    &__wrap {
      .block-template-sticky-footer-offer-v1 {
        margin-bottom: 0;
      }
    }
  }
}
