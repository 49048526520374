$disabled-color: #cccccc;
$disabled-cursor: text;
$ipad-min-width: 768px;
$ipad-max-width: 1024px;

#shipping-info-panel,
#billing-info-panel {
  .address-container address {
    font-style: normal;
  }
}

.checkout .checkout__content {
  #viewcart-panel {
    @include viewcart-panel;
  }
  #bottom-viewcart-buttons {
    @include viewcart-panel;
  }
  .checkout-buttons-container input.disabled {
    @include disable-button();
  }
}

#colorbox {
  .waitlist-iframe-wrapper {
    width: 100%;
    height: 95%;
  }
}

.tiny-waitlist-overlay h2 {
  margin: 30px 0 30px 0;
  text-align: left;
}

.tiny-waitlist-overlay .field {
  border-top: 1px solid gray;
  padding-top: 40px;
}

.tiny-waitlist-overlay .email_input {
  display: inline-block;
}

.tiny-waitlist-overlay .email_input input[type='text'] {
  width: 150px;
  margin-bottom: 10px;
}

.tiny-waitlist-overlay .field .align-r {
  display: inline-block;
  margin-left: 10px;
}

.waitlist_thankyou_message {
  font-family: 'Brandon Text Bold';
  margin: 130px 60px 90px 115px;
  line-height: 1.45em;
}

#billing-address-info-panel {
  #billing_address_form_container {
    border: 1px solid #000;
    padding: 20px;
    margin-top: 10px;
    .postal_code_container {
      input {
        width: 50%;
        margin-right: 10px;
      }
      .postal-code-error {
        color: #ff4661;
        margin-bottom: 10px;
      }
    }
    .city_container .city-results {
      margin-top: 10px;
      margin-bottom: 15px;
      a {
        border: 1px solid;
      }
    }
    .title_container {
      margin-bottom: 10px;
    }
  }
}

#recommended-products-panel {
  @media only screen and (max-device-width: $ipad-min-width) {
    .recommended-products-panel__content {
      padding: 15px;
      .recommended-product-items .recommended-item {
        .add_button input {
          font-size: 11px;
        }
      }
    }
  }
  @media only screen and (max-device-width: $ipad-max-width) {
    .recommended-products-panel__content {
      padding: 15px;
    }
  }
}

@media only screen and (max-device-width: $ipad-max-width) {
  #viewcart-panel {
    .viewcart-buttons-panel {
      a.continue-shopping {
        margin-top: 0px;
      }
    }
  }
}

@media only screen and (max-device-width: $ipad-min-width) {
  #viewcart-panel {
    .viewcart-buttons-panel {
      .continue-buttons {
        float: none;
        margin-bottom: 10px;
        a {
          width: 100%;
        }
      }
      a.continue-shopping {
        width: 100%;
      }
    }
  }
}

#order-summary-panel {
  .order-summary__content {
    .order-summary__tax-value {
      line-height: normal;
    }
    .label,
    .value {
      font-weight: bold;
      padding: 0px;
      text-transform: none;
    }
    .summary_section_separater {
      border-bottom: 1px solid;
      float: left;
      width: 100%;
    }
    .order-summary__total-label,
    .order-summary__total-value {
      margin-top: 15px;
    }
  }
}

#shipping-panel {
  .transaction-panel-details {
    .transaction-item.gift_container {
      clear: both;
    }
  }
}

.checkout-co-enabled {
  .pg_wrapper {
    .right {
      #order-summary-panel {
        .order-summary {
          &__content {
            .summary_section_separater {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}

h3 {
  &.delivery-title {
    margin-bottom: 22px;
  }
}

.delivery_option {
  border: 1px solid $color-black;
  border-radius: 2px;
  cursor: pointer;
  flex: 1 1 50%;
  height: 96px;
  opacity: 0.4;
  padding: 55px 0 20px;
  text-align: center;
  text-transform: uppercase;
  &.active {
    opacity: 2;
  }
}

.home_delivery_option {
  background: url('/media/images/checkout/home_delivery_icon.png') no-repeat center 22px;
  margin-right: 11px;
}

.click_and_collect_option {
  background: url('/media/images/checkout/pickpoint-icon.png') no-repeat center 22px;
  background-size: 30px;
  margin-left: 11px;
}

#delivery-options {
  .shipform_fields {
    .ship-method-group-label {
      display: flex;
      margin-bottom: 13px;
      input[type='radio'] {
        position: fixed;
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}

.checkout {
  .offer-code-panel {
    .offer_code_box {
      span {
        input[type='text'] {
          margin: 10px 0;
          height: 40px;
        }
      }
      .btn {
        &.form-submit {
          width: 100%;
          height: 40px;
        }
      }
      em {
        &#one-offer-only {
          span {
            float: none;
            a {
              font-style: normal;
              letter-spacing: 1.5px;
              color: $color-pink;
              border-bottom: 1px solid $color-pink;
            }
          }
        }
      }
    }
  }
}

.shipping-information {
  text-transform: uppercase;
  font-size: 15px;
  display: block;
  text-decoration: none;
  line-height: 1.3;
  text-indent: 0;
  margin: 27px 0;
  a {
    &.overlay-link-shipping-options {
      color: $color-black;
      text-decoration: none;
    }
  }
  .info-icon {
    display: inline-block;
    background: $color-gray-link;
    margin-left: 5px;
  }
}

.payment_method_container {
  #payment-type-cc {
    img {
      width: 50px;
    }
  }
}

