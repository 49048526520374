.site-header__menu {
  &-content-promo {
    @include breakpoint($small-down) {
      min-height: 45px;
    }
  }
}

.site-email-sms-thank-you {
  &__header {
    h1 {
      height: auto;
    }
  }
}

@media (min-width: #{$landscape-up}) {
  .site-header {
    .site-utils {
      &__search--desktop {
        top: -36px;
      }
    }
  }
}

.content-formatter {
  &__grid {
    .flex-lg-1 {
      @include breakpoint($medium-up) {
        flex: none;
        padding-bottom: 60px;
      }
    }
    .flex-sm-1 {
      @include breakpoint($medium-down) {
        flex: none;
      }
    }
    .t3 {
      @include breakpoint($landscape-up) {
        font-size: 30px;
      }
    }
  }
  &__grid-large {
    @include breakpoint($medium-up) {
      justify-content: center;
    }
  }
  &__text {
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }
  .content-formatter__content {
    .content-formatter__rendered {
      & > .content-block--basic-tout {
        height: 100%;
        .content-block--default {
          height: 100%;
        }
        .content-block__content-text {
          &.padding--bare {
            display: flex;
            flex-direction: column;
            height: 100%;
          }
          .content-block__media {
            display: flex;
            flex-grow: 0;
          }
          .content-block__content {
            display: flex;
            flex-grow: 1;
          }
        }
      }
    }
  }
}

/* Fix for "i" info icon display */
.pg_wrapper {
  .info-content {
    display: flex;
    margin: 15px 0;
    .info-icon {
      margin-#{$rdirection}: 5px;
      padding: 0;
      text-align: center;
    }
  }
}

.checkout {
  .ship-method-group {
    border-bottom: 1px solid;
  }
  .shipmethod-home-delivery,
  .delivery-options {
    padding: 5px 30px 9px;
    clear: both;
    display: block;
    border-top: 1px solid;
    input {
      height: 17px;
      width: 17px;
    }
  }
  .btn-disable {
    pointer-events: none;
  }
}

.tiny-waitlist-overlay {
  &.bis-signup {
    .field {
      .align-r {
        input[type='submit'] {
          width: auto;
        }
      }
    }
  }
}

.grid-row-formatter {
  &__grid-item {
    &:not(:last-of-type) {
      &:not(.grid-row-formatter__grid-item--zero-margin) {
        @include breakpoint($medium-down) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.mpp-grid {
  &__item {
    .product-brief {
      justify-content: space-between;
    }
  }
}

.content-block-product-card {
  &.dark-background {
    .product-brief {
      &__sized-buttons {
        .sku-list {
          &__list {
            .sku-list {
              &__item {
                &:not(:only-child) {
                  .sku-list {
                    &__button {
                      &.button--active {
                        color: $color-core-black;
                      }
                    }
                  }
                }
                .sku-list {
                  &__button {
                    color: $color-gray-op50;
                    &:hover {
                      color: $color-white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.tout-block {
  .tout-block__wrapper-pad-default {
    .content-block__content {
      & > .tout-block__content {
        justify-content: space-between;
      }
      .content-block__content {
        order: 1;
      }
      .tout-block__link-wrapper {
        order: 3;
      }
    }
  }
}

.elc-order-tracking__content {
  .elc-order-tracking-status-description {
    .elc-order-tracking-status-description-title {
      margin-top: 30px;
    }
  }
}
